
        @import "@/styles/griddle-overrides.scss";
        @import "@braid/griddle/scss/griddle.scss";
        








































































































































@import '@/styles/_colors';

$imageSize: 1.75em;

.dashboard {
  --columns: 3;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: grid;
  grid-gap: 1em;
  grid-template-rows: repeat(6, 1fr);
  grid-template-columns: repeat(5, 1fr) auto;
  justify-content: start;
  width: 100vw;
  height: 100vh;
  padding: 1.5em;
  color: white;
  font-size: calc(12px + (20 - 12) * (100vw - 600px) / (2000 - 600));
  background-color: #020218;
  background-image: url('/dashboard-1.png');
  background-size: cover;
}

.meta {
  display: grid;
  grid-gap: 1em;
  grid-row: 1 / span 6;
  grid-column: 1;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: auto;
  margin-right: 1em;
}

.meta__panel {
  height: 100%;
  padding: 1em;
  background: rgba(black, 0.35);
  border-radius: 0.15em;
}

.meta__panel--header {
  margin-bottom: 0.5em;
  padding-bottom: 0.5em;
  color: $color-yellow;
  font-weight: 500;
  border-bottom: 1px solid rgba(white, 0.1);

  > .fa {
    margin-right: 0.5em;
  }
}

.meta__team {
  display: flex;
  flex-direction: column;

  overflow: hidden;
}

.meta__team--member {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
}

.meta__team--name {
  margin-left: 0.5em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.meta__team--image {
  width: $imageSize;
  height: $imageSize;
  border-radius: $imageSize / 2;
}

.meta__product {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.meta__product--image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12em;
  height: 12em;
  margin: 0 auto;
  object-fit: cover;
  color: black;
  background: #4b6455; // Knowit forest
  border: 5px solid white;
  border-radius: 0.25em;
  box-shadow: 0 0.4em 1.6em 0.5em rgba($color-yellow, 0.4);
}

.meta__product--name {
  margin-top: 1em;
  color: $color-yellow;
  font-weight: 500;
  font-size: 1.25em;
}

.close {
  grid-column: 6;
  justify-self: end;
  width: 100%;
}

.close--btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3em;
  height: 3em;
  padding: 0;
  color: white;

  &:hover {
    background: rgba(white, 0.1);
  }
}

.objective {
  display: grid;
  grid-gap: 1em;
  grid-row: 1 / span 6;
  grid-template-rows: repeat(6, 1fr);
  justify-content: center;
  width: 100%;
  margin: 0;
  font-size: 0.9em;
}

.objective__head {
  display: grid;
  grid-gap: 0.5em;
  grid-template-rows: 2.5em auto;
  align-content: center;
  text-align: center;
}

.objective__icon {
  display: flex;
  grid-row: 1;
  align-items: center;
  align-self: end;
  justify-content: center;
  justify-self: center;
  width: 2.5em;
  height: 2.5em;
  color: var(--color-text);
  background: var(--color-primary);
}

.objective__name {
  grid-row: 2;
  font-weight: 500;
  font-size: 1.2em;
}

.objective__tag {
  display: inline-block;
  padding: 0.25em 0.5em;
  color: $color-purple;
  font-weight: 500;
  background: white;
}

.key-result {
  display: grid;
  grid-gap: 0.5em;
  grid-row: auto 3rem;
  grid-column: 1fr;
  height: 100%;
  padding: 1em;
  color: rgba(white, 0.85);
  font-weight: 300;
  background: rgba(black, 0.35);
  border-radius: 0.15em;
}

.key-result__progress {
  align-self: end;
  justify-content: end;
}
